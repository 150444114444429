import React from "react"
import { graphql } from "gatsby"
// import SanityImage from "gatsby-plugin-sanity-image"
import Layout from "../components/Layout"
import Listing from "../components/Listing"
import Seo from "../components/Seo"

export default function Topic({ data }) {
  //Data destructuring
  const articlesData = data.articles.edges.map(edge => edge.node)
  const topicData = data.topic
  const seo = data.seo

  //Define & Assign
  const titleRu =
    topicData.title && topicData.title.ru ? topicData.title.ru : ""
  const titleFi =
    topicData.title && topicData.title.fi ? topicData.title.fi : ""
  const descRu =
    topicData.description && topicData.description.ru
      ? topicData.description.ru
      : ""
  const descFi =
    topicData.description && topicData.description.fi
      ? topicData.description.fi
      : ""
  // const alt = `${titleRu} / ${titleFi}`
  // const imageWidthV = 640
  // const imageWidthH = 1500

  // let assetH = null
  // let assetV = null
  // if (topicData.covers.length > 0) {
  //   let setH = false
  //   for (const cover of topicData.covers) {
  //     // let setV = false
  //     if (cover.format === "square-animated") {
  //       assetH = cover.image && cover.image.asset
  //       setH = true
  //     } else if (!setH && cover.format === "square") {
  //       assetH = cover.image && cover.image.asset
  //       setH = true
  //     } else if (!setH && cover.format === "landscape-animated") {
  //       assetH = cover.image && cover.image.asset
  //       setH = true
  //     } else if (!setH && cover.format === "landscape") {
  //       assetH = cover.image && cover.image.asset
  //     }
  //   }
  // }

  return (
    <Layout>
      <Seo seoData={seo} />
      <section className="issue-header">
        <div className="issue-info">
          <h1 className="issue-title">
            <span className="russian" lang="ru">
              {titleRu}
            </span>
            <span className="finnish" lang="fi">
              {titleFi}
            </span>
          </h1>
          {descRu && descFi && (
            <p className="issue-desc">
              <span className="russian" lang="ru">
                {descRu}
              </span>
              <span className="finnish" lang="fi">
                {descFi}
              </span>
            </p>
          )}
        </div>
        {/* {assetV && <SanityImage asset={assetV} alt={alt} width={imageWidthV} className="issue-cover vertical" />} */}
        {/* {assetH && <SanityImage asset={assetH} alt={alt} width={imageWidthH} className="issue-cover" />} */}
      </section>
      <section className="issue-body">
        <Listing data={articlesData} pagesPerLoad={24} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GetTopic($id: String) {
    topic: sanityTopic(_id: { eq: $id }) {
      _id
      _key
      title {
        fi
        ru
      }
      covers {
        format
        image {
          asset {
            _id
          }
        }
      }
      description {
        fi
        ru
      }
    }
    articles: allSanityArticle(
      filter: { topics: { elemMatch: { _id: { eq: $id } } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          _id
          _type
          date
          slug {
            current
          }
          cover {
            asset {
              _id
            }
          }
          thumb {
            asset {
              _id
            }
          }
          title {
            fi
            ru
          }
          description {
            fi
            ru
          }
          section {
            title {
              ru
              fi
            }
            slug {
              current
            }
          }
          author {
            name {
              ru
              fi
            }
          }
        }
      }
    }
    seo: sanityTopic(_id: { eq: $id }) {
      _id
      _type
      title {
        fi
        ru
      }
      slug {
        current
      }
      covers {
        format
        image {
          asset {
            _id
          }
        }
      }
      description {
        fi
        ru
      }
      seo {
        metaTitle
        metaDescription
        metaKeywords
        sharedImage {
          asset {
            _id
          }
        }
        preventIndexing
      }
    }
  }
`
